// Global alert box
function alertBox(title, txt, button) {
    $('.alert__title').html(title);
    $('.alert__txt p').html(txt);
    if(button != '') {
      $('.alert__button').html(button);
    } else {
      $('.alert__button').hide();
    }
    $('.alert').addClass('on');
}

$('.alert__button').click(function(){
  $('.alert').removeClass('on');
  return false;
});
