// Time to load out THNG
// ***************
function loadThng(appId) {

    if (!thngId) {

        // Fall-back if no THNG ID found in URL
        // ***************
        $('body').addClass('start');

    } else {

        // Create anon user / retrieve created anon user
        // ***************
        getUser(appId)

        // Read our THNG and build the screen
        // ***************
        .then(readThng);

        function readThng(user) {

            // Read thng, then product to get the picture to show on the App
            // ***************
            user.thng(thngId).read().then(function(thng) {

            user.product(thng.product).read().then(function(product) {

                    // Check the post-purchase state of the THNG
                    user.thng(thngId).property('post-purchase').read().then(function(results) {

                        // Get the latest post-purchase value
                        var purchaseState = results[0].value;

                        // Allow the post-purchase state to be toggled
                        $('.changePurchaseState').click(function(){
                          changePurchaseState(thng, user, purchaseState);
                          return false;
                        });

                        // Allow Registered Bear to be unregistered and returned to post-purchase/register pending state
                        $('.removeRegistration').click(function(){
                          removeRegistration(thng, user);
                          return false;
                        });

                        // Add an Upsell Action when 'Buy Bear Accessories' is clicked
                        $('.upsellAction').click(function(){
                          addAction(thng, '_viewUpsell');
                        });


                        // If the value is true (is in the post-purchase state)
                        if(purchaseState == true) {

                            // Check if there is a tag present
                            var thngTags = thng.tags || [];
                            var thngTagNumber = thngTags.length;

                            // If there is tag present...
                            if(thngTagNumber > 0) {

                              // Get the ID of the scanning user
                              var idStorageKey = ''+ appId + '-userId';
                              var storedId = localStorage.getItem(idStorageKey);

                              // If the ID matches that on the Scanned THNG take the user to the modified product info page.
                              if(storedId == thngTags[0]) {

                                // ## Add Action ## to show user re-scan
                                addAction(thng, '_postRegistration');

                                state = 'offer';
                                populateThngTemplate(state, product);
                                $('#offer h1').html(thng.name);

                                // Hide fields/tailor post-purchase product page
                                $('#offer').addClass('productOwned');

                              // If it doesn't match then the bear is lost!
                              } else {

                                // ## Add Action ## to show lost bear scan
                                addAction(thng, '_nonOwnerScan');

                                state = 'lost';
                                $('#lost .bearName').html(thng.name);
                                $('#lost .bearOwner').html(thng.customFields.owner);
                                $('#lost .button').attr('href', 'mailto:' + thng.customFields.email + '?subject=I%20have%20found%20your%20Bear!');

                              }

                            // If there are no Tags present
                            } else {

                              state = 'registration';
                              populateThngTemplate(state, product);

                              $('#registerThng').submit(function(){

                                // ## Add Action ## to show lost bear scan
                                addAction(thng, '_productRegistration');

                                var firstNameNew = $('#user__name').val();
                                var emailNew = $('#user__email').val();
                                var bearName = $('#user__bearname').val();

                                  var update = {
                                    firstName: firstNameNew
                                  }

                                  var updateCustomFields = {
                              			owner: firstNameNew,
                              			email: emailNew
                              		}

                                  user.update(update).then(function(){

                                    thngTags.push(user.id);

                                    var updateTags = thngTags;

                                    user.thng(thngId).read().then((thng) => {

                                        thng.name = bearName;
                                        thng.tags = thngTags;
                                        thng.customFields = {'owner': firstNameNew, 'email': emailNew};

                                        thng.update().then(function(){

                                          state = 'offer';
                                          populateThngTemplate(state, product);

                                          toaster('Registration Completed');
                                          $('#offer').addClass('productOwned');
                                          $('#offer h1').html(bearName);
                                          $('body').removeClass('registration').addClass('offer');

                                        });
                                    });

                                  });

                                return false;

                              });

                            }

                        // If it's not in the post-purchase state then take the user to the product overview/purchase screen
                        } else {

                            state = 'offer';
                            populateThngTemplate(state, product);

                            // Handle clicking on the 'Purchase Online' button
                            $('.offer__button').click(function(){
                              // alertBox('Added to Basket', 'This is for demonstration purposes only.', 'Close');
                              alertBox('Added to Basket', 'Please wait for registration screen.', '');
                              // ## Add Action ## to show buy online clicked
                              addAction(thng, '_checkoutMobile');
                              // Switch purchase purchaseState
                              changePurchaseState(thng, user, false);
                            });

                        }

                        $('body').addClass(state);

                    });

                    $('.reorder-list__items').on('click', '.reorder-list__item', function() {
                      alertBox('Added to Basket', 'This is for demonstration purposes only.', 'Close');
                      return false;
                    });

                    // Check for photos - provide fallback if no photo found
                    // ***************
                    if (product.photos) {
                        $('.hero').attr("src", product.photos[0]);
                    } else {
                        $('.hero').attr("src", '/assets/placeholder.png');
                    }

                    if (product.customFields.price) {
                        $('.price').html(product.customFields.price);
                    }

                    // Read reviews items
                    // ***************
                    if (product.customFields.review01) {
                        showReviews(customFieldArrayToJson('review', product.customFields));
                    } else {
                        $('.rating-summary').removeClass('overlay-toggle');
                    }

                    // Read provenance items
                    // ***************
                    if (product.customFields.provenance01) {
                        showProvenance(customFieldArrayToJson('provenance', product.customFields));
                    }

                    // Read sustainability items
                    // ***************
                    if (product.customFields.sustainability01) {
                        showSustainability(customFieldArrayToJson('sustainability', product.customFields))
                    }

                    // Lets do some BlockChain Action Magic - add the link URLs to the already populated Provenance information panel
                    // We start by checking for OriginTrail Authenticity
                    user.thng(thngId).action('_originTrailCertified').read().then(function(actionsOT){
                      if(actionsOT[0]) {
                        var authenticatedURL = actionsOT[0].customFields.originTrailUrl;
                        $('.sustainability-item.item-0 .blockchainManufactured').attr("href", authenticatedURL);
                        $('.sustainability-item.item-0 .blockchainManufactured').text("Authenticity certified via Origintrail");
                      } else {
                        // If it was not certified with OriginTrail we fallback to the Bitcoin blockchain...
                        user.thng(thngId).action('_blockchainValidated').read().then(function(actions){
                          if(actions[0]) {
                            var shippedUrl = 'https://blockchain.info/tx/' + actions[0].customFields.bitcoinTrxId + '';
                            var manufacturedURL = 'https://blockchain.info/tx/' + actions[1].customFields.bitcoinTrxId + '';
                            $('.sustainability-item.item-0 .blockchainShipped').attr("href", shippedUrl);
                            $('.sustainability-item.item-0 .blockchainManufactured').attr("href", manufacturedURL);
                          }
                        });
                      }
                    });

                    // Time to do a sneeky switch of product info for the serial number
                    $('.provenance-item.item-4 .list-value').html(thng.identifiers.ser);

                    // Read reorder items
                    // ***************
                    if (product.customFields.reorder01) {
                        showReorder(customFieldArrayToJson('reorder', product.customFields));
                    }

                })

            });

        }
    }

}
// Template Population END
