var toasterTimer;
function toaster(message, type) {

  clearTimeout(toasterTimer);

    $('.toaster').removeClass("error warning");

    if (type) {

      $('.toaster').addClass(type);

    }

    $('.toaster__message').html(message);
    $('.toaster').addClass('on');

    toasterTimer = setTimeout(function(){
        $('.toaster').removeClass('on');
    }, 4000);

    $('.toaster').click(function(){
      $(this).removeClass('on');
    });

}
